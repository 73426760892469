:root {
  --borderRadius: 0.5rem;
}

.eventView {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 0.75rem;
  line-height: 1em;
  padding: 0.15rem;
  position: relative;
  margin: 0 0 1px 0;
}

.eventName {
  composes: truncates from '../../utils/text.css';
  margin: auto;
  display: block;
}

.center {
  margin: auto;
}

.left {
  margin-left: 0.15rem;
}

.right {
  margin-right: 0.15rem;
}

.starts {
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

.ends {
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.simplified,
.limited {
  padding: 0;
}

.courier {
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter',
    'Lucida Typewriter', monospace;
}

.helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.lucida {
  font-family: 'Lucida Bright', Georgia, serif;
}

.times {
  font-family: TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia,
    serif;
}

.comic {
  font-family: 'Comic Sans', 'Comic Sans MS', cursive;
}
