@import "../../utils/colors.css";

.inlineSetting {
  margin: auto;
  margin-right: 0.5rem;
  color: var(--voodoo);
  font-size: 14px;
}

.inlineSetting.additionalSpacing {
  margin-right: 0.875rem;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 0.5;
}

.active {
  opacity: 0.8;
}

.visible:hover {
  opacity: 0.9;
}

.disabled,
.disabled:hover {
  opacity: 0.25;
  cursor: no-drop;
}
