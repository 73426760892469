.group {
  margin-bottom: 4px;
}

.error {
  color: #900;
}
.error label {
  font-weight: bold;
}

:global(.error .form-control) {
  color: #900;
}
