@import "../../utils/colors.css";

.header {
  display: flex;
  padding: 4px 0 5px 10px;
  background: #fff;
  color: var(--navBarColor);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.draggableHeader {
  cursor: pointer;
  pointer-events: all;
  background: var(--navBarColor);
  color: #fff;
}

.draggableHeader:hover {
  background: var(--navBarColorLight);
}
