@import "../../utils/colors.css";
@import "./_shared.css";

.day {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: 0;
  position: relative;
  border-top: 1px solid var(--calendarBorderColor);
  border-right: 1px solid var(--calendarBorderColor);
  user-select: none;
}

.indicator {
  display: block;
  margin: auto 1px;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.indicator.simplified {
  height: 8px;
  width: 8px;
}

@media print {
  .day {
    border-top: 1px solid var(--calendarBorderColor);
    border-right: 1px solid var(--calendarBorderColor);
  }
}

.day:last-child {
  border-right: 0;
}

@media print {
  .day:last-child {
    border-right: 0;
  }
}

.day:hover {
  background: var(--lochivarLight);
}

.daySelectContainer {
  cursor: pointer;
  padding: 0.1rem 0 0 0.1rem;
  position: relative;
  display: flex;
  position: absolute;
  height: 1.75rem;
  top: 0;
  left: 0;
  width: 100%;
}
.daySelectContainer.simplified {
  height: 1rem;
}

.daySelectSpacer {
  display: block;
  flex-grow: 1;
}

.displayDate {
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
  line-height: 1em;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  flex-basis: 1.5rem;
  border-radius: 1rem;
  align-items: center;
  align-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.displayDate span {
  margin: auto;
}

.displayDate.simplified {
  font-size: 0.5875rem;
  width: 0.875rem;
  height: 0.875rem;
  flex-basis: 0.875rem;
}

@media print {
  .displayDate {
    font-size: 0.825rem;
    width: 0.95rem;
    height: 0.925rem;
    flex-basis: 0.925rem;
    background: transparent !important;
    color: var(--rose) !important;
  }
}

.holidayName {
  composes: truncates from "../../utils/text.css";
  font-size: 0.875rem;
  line-height: 1em;
  height: 1.5rem;
  display: flex;
  align-items: center;
  align-content: center;
}

.holidayName.simplified {
  height: 0.875rem;
  font-size: 0.5rem;
}

@media print {
  .holidayName {
    height: 0.875rem;
    font-size: 0.8rem;
    padding-left: 0.5em;
  }
}

@media screen {
  .currentDay.displayDate {
    background: var(--lochivar);
    color: #fff;
  }
}

.weekend {
  background: var(--calendarWeekendColor);
  color: var(--rose);
}

.holiday {
  background: var(--calendarWeekendColor);
  color: var(--rose);
}

.prevMonth,
.nextMonth {
  color: var(--rose);
  background: var(--roseMedium);
}

.prevMonth:hover,
.nextMonth:hover {
  background: var(--roseLight);
}

.highlight {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.hover {
  composes: highlight;
  border: 2px dotted var(--rose);
  opacity: 0.75;
  pointer-events: none;
}

.neutral {
  composes: highlight;
  display: none;
}

.selectable {
  composes: highlight;
  cursor: pointer;
  border: 2px dotted var(--lochivar);
  z-index: 0;
  pointer-events: none;
}

@media print {
  .selectable {
    display: none;
  }
}

.settingsButton {
  padding: 0 0.25rem 0 0.5rem;
  margin: auto;
  background: none;
  border: none;
  color: var(--rose);
}

.settingsButton.simplified {
  font-size: 0.5875rem;
  line-height: 1em;
}

.settingsButton:focus {
  outline: none;
}

.dayMenuDropDown {
  position: absolute;
  z-index: 3;
  background: #fff;
  box-shadow: 0 2px 2px var(--roseLight);
  padding: 0.25rem;
  right: 0.25rem;
  left: 0.25rem;
  top: 0.25rem;
  min-width: 8rem;
  border: 1px solid var(--roseLightest);
}

.dayMenuDropDown.simplified {
  left: auto;
}

.dayMenuDropDown ul,
.dayMenuDropDown li {
  list-style: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

.dayMenuDropDown .dropDownTitle {
  color: var(--rose);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
}

.dayMenuDropDown .dropDownItem {
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--roseLightest);
  font-size: 0.75rem;
  display: flex;
}

.dayMenuDropDown .dropDownItem:last-child {
  border-bottom: 0;
}

.dayMenuDropDown .dropdownButton {
  background: none;
  border: none;
  color: var(--voodoo);
  display: flex;
  text-align: left;
}

.dayMenuDropDown .dropdownButton i,
.dayMenuDropDown .dropdownButton span {
  margin: auto;
}

.dayMenuDropDown .dropdownButton i {
  flex-grow: 0;
  padding: 0 0.5rem 0 0;
}

.dayMenuDropDown .dropdownButton span {
  flex-grow: 1;
}
