@import "../../utils/metrics.css";
@import "../../utils/mediaTypes.css";

@media screen {
  .container {
    position: fixed;
    top: 0;
    left: var(--navBarSizeSmall);
    height: 100vh;
    overflow: hidden;
    top: 0;
    margin: 0;
    width: 0;
  }
}

@media screen and (min-width: 768px) {
  .container {
    position: fixed;
    top: 0;
    left: var(--navBarSizeSmall);
    height: 100vh;
    overflow: hidden;
    top: 0;
    margin: 0;
    width: 0;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    left: var(--navBarSize);
  }
}

@media print {
  .container {
    display: none;
  }
}

.container.in {
  background: RGBA(255, 255, 255, 0.35);
  width: 100%;
  min-height: 100vh;
}

.content {
  background: #f9f9f9;
  border-right: 1px solid #ddd;
  box-shadow: 0 0 10px RGBA(62, 49, 60, 0.15);
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: var(--drawerSize);
}

@media screen and (min-width: 768px) {
  .content {
    width: var(--drawerSize);
    margin-right: 5px;
    transition: transform 0.1s ease-out;
    transform: translate3d(-var(--drawerSize), 0, 0);
  }
}

.in {
  transform: translate3d(0, 0, 0);
}

.titleContainer {
  align-self: flex-start;
  display: none;
  background: #fff;
  height: 64px;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  padding: 0;
}

@media (min-width: 768px) {
  .titleContainer {
    display: flex;
  }
}

.titleHeading {
  font-size: 1rem;
  line-height: 1rem;
  margin: auto;
  display: block;
  flex-grow: 1;
  padding: 0 1rem;
}

.close {
  background: transparent;
  display: block;
  margin: auto;
  color: #ab9ba9;
  padding: 0 1rem;
  height: 100%;
  border: 0;
}

.close:focus {
  outline: none;
}
