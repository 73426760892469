.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.container > * {
  margin: 0 10px;
}

.containerColumn {
  flex-direction: column;
}

.containerStart {
  align-items: flex-start;
  flex-grow: 1;
}

.containerEnd {
  align-items: flex-end;
}

.fill {
  height: 100%;
  width: 100%;
}
