@import "../../utils/mediaTypes.css";

.background {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
}

@media (min-width: 375px) {
  .background {
    align-items: flex-start;
    align-content: flex-start;
  }
}

@media (min-width: 768px) {
  .background {
    align-items: center;
    align-content: center;
  }
}
