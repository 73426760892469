@import "../../utils/colors.css";

.label {
  font-weight: 600;
}

.example {
  color: var(--rose);
  margin: 0.25rem 0 0 0;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.fieldContainer {
  display: flex;
  position: relative;
}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  height: 2.5rem;
}

.colorField {
  display: block;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--roseLight);
  position: relative;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.colorField:focus {
  outline-style: dotted;
  outline-width: 1px;
  outline-radius: 0.25rem;
}

.closeButton {
  position: absolute;
  background: #fff;
  color: var(--lochivar);
  top: -1rem;
  right: -1rem;
  box-shadow: 1px 1px 3px var(--rose);
  display: flex;
  border: 1px solid var(--roseLight);
  border-radius: 1rem;
  height: 1.5rem;
}

.closeButton svg {
  display: block;
  color: var(--sorbus);
  margin: auto;
  height: 1rem;
  width: 1rem;
}

.pickerContainer {
  position: absolute;
  top: 2.75rem;
  width: 12rem;
  z-index: 3;
}

.pickerDismiss {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

.checkbox {
  margin-right: 0.5rem;
}

.autocompleteList {
  background: #fff;
  border-radius: 0.25rem;
  border: 1px solid var(--roseMedium);
  position: absolute;
  top: 2.75rem;
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 0 var(--roseMedium);
}

.autocompleteList li {
  display: flex;
  list-style: none;
  padding: none;
  flex-grow: 1;
}

.autocompleteList button {
  display: flex;
  flex-grow: 1;
  background: none;
  border: none;
  color: var(--voodoo);
  font-weight: bold;
  padding: 0.5rem 1rem;
}
