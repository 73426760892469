.listContainer {
  margin: 0;
  padding: 0;
}

.addItem {
  composes: listItem from "./ShortListItem.module.css";
  padding: 0.5rem 0.75rem;
}

.loadingItem {
  composes: listItem from "./ShortListItem.module.css";
  background: transparent;
  border-bottom: none;
  display: flex;
  align-content: center;
  align-items: center;
}

.loadingItem > * {
  margin: auto;
}
