@import '../../utils/colors.css';

.shortListLabel {
  composes: truncates from '../../utils/text.css';
  flex-grow: 1;
  display: flex;
}

.primaryText {
  composes: shortListLabel;
  line-height: 1.875em;
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--voodoo);
}

.secondaryText {
  composes: shortListLabel;
  font-size: 0.75rem;
  color: var(--rose);
}

.primaryErrorText {
  composes: secondaryText;
  color: var(--danger);
}

.secondaryErrorText {
  composes: secondaryText;
  color: var(--danger);
}

.icon {
  margin: auto 0.5rem auto 0;
}
