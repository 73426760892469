@import "../../utils/colors.css";

.itemView {
  composes: categoryView from "./CategoryView.module.css";
  margin-left: 1rem;
}

.itemActive {
  margin: auto;
  margin-right: 0.5rem;
}

.name {
  flex-grow: 1;
  margin: auto;
}

.highlight {
  background: var(--roseLightest);
  font-weight: bold;
  color: var(--sorbusDark);
  padding: 0;
}

.custom {
  font-style: italic;
}

.custom:before {
  content: "*";
  margin-right: 0.25rem;
}
