.region {
  display: flex;
  flex: 1;
  border: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 1px;
}

@media print {
  .region {
    border: none;
    margin: 0;
  }
}
