@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";

.itemExplorer {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 60px);
}

.hover {
  background: var(--roseLightest);
}

.topLevelContainer {
  composes: selectableItem from "./SelectableItem.module.css";
}

.topLevel {
  composes: categoryView from "./CategoryView.module.css";
}

.topLevelName {
  composes: name from "./CategoryView.module.css";
}

.topLevelIcon {
  margin: auto 0.5rem auto 0;
}

.searchHeading {
  border-bottom: 1px solid var(--roseLight);
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  font-weight: bold;
}

.searchContainer {
  display: flex;
  width: 100%;
  padding: 0.5rem;
}

.searchField {
  border-radius: 1rem;
  border: 1px solid var(--roseLight);
  padding: 0.2rem 1rem;
  flex-grow: 1;
  display: flex;
  margin: 0;
}
