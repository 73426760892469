@import "../../utils/colors.css";

.closeButton {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1em;
  margin: auto 0;
  padding: 0.25rem 0.5rem;
}
