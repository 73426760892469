@import "../../utils/metrics.css";
@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";

:global {
  body {
    @media print {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
}

.container {
  flex-direction: column;
  display: flex;
  align-items: stretch;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.barContainer {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}

@media print {
  .container {
    width: 100%;
    height: 100%;
  }
}

.content {
  width: auto;
  background: #fefefe;
  position: relative;
  flex-grow: 1;
}

.logo {
  background: #f6f5f6;
  display: block;
  margin: 0;
  height: 50px;
  flex-grow: 1;
  flex-shrink: 0;
  border-right: 1px solid var(--roseMedium);
  background-image: url(../../images/ProCal-logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 28px;
}

@media screen and (min-width: 1024px) {
  .logo {
    background-size: 44px 42px;
  }
}

.footer {
  background: #fff;
  border-top: 1px solid var(--roseMedium);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  position: fixed;
  display: none;
  bottom: 0;
  width: 100vw;
  z-index: 10;
}

.footerContent {
  margin: 0;
  display: flex;
  flex-grow: 1;
  margin-left: 4rem;
}

.crewvie,
.crewvie img {
  display: block;
  width: 60px;
  height: auto;
  margin: 0 auto auto 2px;
}

@media (min-width: 992px) {
  .footer {
    display: flex;
  }
}

@media print {
  .footer {
    display: none;
  }
}

.contentScroll {
  width: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

@media screen and (min-width: 347px) {
  .contentScroll {
    width: auto;
    margin-left: var(--navBarSizeSmall);
  }
}

@media screen and (min-width: 1024px) {
  .contentScroll {
    margin-left: var(--navBarSize);
  }
}

@media screen {
  .contentScroll {
    overflow: hidden;
  }
}

@media print {
  .contentScroll {
    width: 100%;
    height: 100%;
    margin-left: 0;
    overflow: scroll;
  }
}
