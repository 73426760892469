@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";
@import "../../utils/metrics.css";

.printControls {
  display: flex;
  flex-direction: row;
  padding: 10px;
  left: 0;
  right: 0;
  background: #f6f5f6;
  border-top: 1px solid var(--roseMedium);
  position: fixed;
  bottom: -10px;
}
@media screen and (min-width: 992px) {
  .printControls {
    flex-direction: column;
    position: relative;
    width: 220px;
    padding: 20px;
    border-top: none;
    border-left: 1px solid var(--roseMedium);
    bottom: auto;
    left: auto;
  }
}

@media print {
  .printControls {
    display: none;
  }
}

.printFields {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 992px) {
  .printFields {
    flex-direction: column;
  }
}

.printControlsContents {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .printControlsContents {
    flex-direction: column;
    width: 180px;
    position: fixed;
    right: 20px;
    top: 20px;
    bottom: 40px;
    max-height: 95vh;
    overflow: auto;
  }
}

.desktopOnly {
  display: none;
}

@media screen and (min-width: 992px) {
  .desktopOnly {
    display: block;
  }
}

.spacer {
  display: flex;
  flex-grow: 1;
}
