@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";

.radioGroupItem:hover {
  background: var(--roseLight);
}

.radioContainer {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  position: relative;
}

.radioContainer.selected .icon,
.radioContainer.selected .label,
.radioContainer.selected .description {
  color: var(--voodoo);
}

.invisibleRadioButton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.invisibleButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0);
}

.icon {
  color: var(--rose);
  margin: auto;
  margin-right: 1rem;
  flex-shrink: 0;
  flex-basis: 2rem;
  max-width: 2rem;
  margin-left: 0;
}

@media (min-width: 375px) {
  .icon {
    display: none;
  }
}

@media (min-width: 544px) {
  .icon {
    display: block;
  }
}

.label {
  color: var(--rose);
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.description {
  padding: 0;
  margin: 0;
  color: var(--rose);
  font-size: 0.875rem;
  line-height: 1.25em;
}
