@import "../../utils/metrics.css";
@import "../../utils/mediaTypes.css";

.toolBar {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 1rem 0.5rem;
  display: flex;
  height: var(--navBarSize);
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  top: 0;
  flex-shrink: 0;
  -webkit-overflow-scrolling: touch;
}

@media print {
  .toolBar {
    display: none !important;
  }
}
