@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";

.button {
  border-radius: 0.25rem;
  padding: 0;
  display: flex;
  border: none;
  color: var(--voodoo);
  background: transparent;
  height: 100%;
  margin: auto 0.5rem;
  position: relative;
}

.selectable {
  padding: 0 0.35rem;
}

@media (min-width: 544px) {
  .required {
    display: flex;
  }
}

.notRequired {
  display: none;
}
@media (min-width: 768px) {
  .notRequired {
    display: flex;
  }
}

.selected {
  background: var(--lochivar);
  border-radius: 50%;
  color: #fff;
}

.button:focus {
  outline: 0;
}

.button:active,
.button:active svg {
  color: var(--rose);
}

.primary,
.primary svg {
  color: var(--lochivar);
}

.promoted,
.promoted svg {
  color: var(--sorbus);
}

.promoted:active,
.promoted:active svg {
  color: var(--sorbusDark);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.primary:active,
.primary:active svg {
  color: var(--lochivarDark);
}

.button svg {
  font-size: 1.25rem;
  line-height: 1em;
  margin: auto;
}

.small svg {
  font-size: 0.875rem;
}

.label {
  margin: auto;
  display: block;
  font-weight: medium;
  line-height: 1em;
  font-size: 0.875rem;
}

.button svg + .label {
  margin-left: 0.5rem;
}
