@import "../../utils/colors.css";

.categoryView {
  display: flex;
  flex-grow: 1;
  padding: 0.1rem 0.5rem;
  min-height: 1.5rem;
  font-size: 0.875rem;
  color: var(--voodooDark);
  opacity: 0.7;
}

.name {
  flex-grow: 1;
  margin: auto;
}

.selected {
  color: var(--voodooDark);
  opacity: 1;
}
