@import "../../utils/colors.css";

.default {
  font-size: 1.5rem;
  display: block;
}

.dark,
.light,
.neutral {
  composes: default;
}

.dark svg path,
.dark svg rect {
  fill: var(--lochivar);
}

.small {
  font-size: 1rem;
  display: block;
}

.large {
  font-size: 3rem;
  display: block;
}

.neutral {
  color: var(--rose);
}

.light {
  color: #fff;
}

.button {
  composes: light;
  display: inline-block;
  font-size: 2rem;
}
