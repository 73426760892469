@import "../../utils/colors.css";

.headline {
  font-size: 1.5rem;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: var(--lochivar);
  color: #fff;
  font-weight: bold;
  margin: 1rem auto;
}
