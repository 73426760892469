@import "../../utils/mediaTypes.css";
@import "../../utils/colors.css";

.toolbarSection {
  display: none;
  height: 2rem;
  position: relative;
}

@media (min-width: 768px) {
  .toolbarSection {
    display: flex;
  }
}

.toolbarSection:first-child {
  padding-left: 0.75rem;
}

@media (min-width: 544px) {
  .toolbarSection:first-child {
    padding-left: 0.75rem;
  }
}

.toolbarSection + .toolbarSection {
  border-left: 1px solid var(--roseNormal);
  padding-left: 0.75rem;
  margin-left: 0.75rem;
}

.short {
  composes: col-lg-2 from "../../utils/grid.module.css";
  composes: col-md-2 from "../../utils/grid.module.css";
  composes: col-sm-2 from "../../utils/grid.module.css";
  composes: col-xs-2 from "../../utils/grid.module.css";
}

.extended {
  composes: col-lg-3 from "../../utils/grid.module.css";
  composes: col-md-3 from "../../utils/grid.module.css";
  composes: col-sm-3 from "../../utils/grid.module.css";
  composes: col-xs-4 from "../../utils/grid.module.css";
}

.grow {
  flex-grow: 1;
  max-width: 100%;
}

.required {
  display: flex;
}

.toolbarSection:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}
