@import "../../utils/colors.css";

.flexRow {
  display: flex;
  margin: 0;
}

.name {
  font-weight: bold;
}

.checkbox {
  margin-right: 0.35rem;
}

.option {
  margin-right: 1rem;
}

.removeLink {
  margin-left: auto;
  color: var(--danger);
  font-size: 0.875rem;
}

.inlineIcon {
  margin-right: 0.5rem;
}

.note {
  display: block;
  color: var(--rose);
  font-size: 0.75rem;
  line-height: 1em;
  margin: 0.5rem 0 1.5rem 0;
}

.dismissLink {
  display: flex;
}

.dismissLink svg {
  margin: auto 0.5rem auto 0;
}
