.section {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

@media print {
  .section {
    border: none;
  }
}

@media print {
  .inactive {
    display: none;
  }
}
