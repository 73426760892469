.body {
  padding: 4px 10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: visible;
}

.noScrolling {
  overflow-y: visible;
}
