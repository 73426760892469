@import "./ShortListItem.module.css";

.settings {
  height: 100%;
  padding: 0 0 0 1rem;
  color: var(--settingsIconColor);
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  padding: 0 0.5rem;
}

.settings > i {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.settings:hover {
  text-decoration: none;
}

.settings:hover > i {
  background: var(--settingsIconColor);
  color: var(--itemBackgroundColor);
}

.settings > * {
  margin: auto;
}
