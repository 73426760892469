:root {
  --gutter-width: 1rem;
  --outer-margin: 2rem;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  --xs-min: 30;
  --sm-min: 48;
  --md-min: 64;
  --lg-min: 75;
  --screen-xs-min: var(--xs-min) em;
  --screen-sm-min: var(--sm-min) em;
  --screen-md-min: var(--md-min) em;
  --screen-lg-min: var(--lg-min) em;
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width));
}

@custom-media only screen and (min-width: 75em) only screen and (min-width: 75em);
