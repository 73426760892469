.folderIconGroup {
  display: flex;
  margin-right: 0.25rem;
  margin-left: 0;
  cursor: pointer;
}

.folderIcon {
  margin: auto;
}

.folderCaret {
  margin: auto;
  margin-right: 0.5rem;
}
