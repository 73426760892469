@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";

.toolbarIcon {
  display: none;
  color: var(--rose);
  font-size: 1.45rem;
  margin: auto 1rem auto 0;
}

@media (min-width: 544px) {
  .toolbarIcon {
    display: flex;
  }
}

.dark {
  color: var(--voodoo);
}

.required {
  display: flex;
}
