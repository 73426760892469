@import "../../utils/colors.css";
@import "../../utils/mediaTypes.css";
@import "./_shared.css";

.calendarMasterDetailContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
}

@media screen {
  .calendarMasterDetailContainer {
    max-height: 100vh;
  }
}

@media print {
  .calendarMasterDetailContainer {
    display: block;
    width: auto;
  }
}

.calendarRendererContainer {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  flex-grow: 1;
  overflow: auto;
}

@media screen {
  .calendarRendererContainer {
    max-height: 100vh;
  }
}

@media print {
  .calendarRendererContainer {
    display: block;
    width: auto;
  }
}

.calendarPrintRendererContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

@media print {
  .calendarPrintRendererContainer {
    display: block;
    width: auto;
    margin: 0;
  }
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  flex-basis: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 768px) {
  .calendarContainer {
    min-height: auto;
    height: 100vh;
    flex-basis: 100vh;
  }
}

@media (min-width: 768px) {
  .calendarContainer {
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1.75rem;
  }
}

@media print {
  .calendarContainer {
    min-height: auto;
  }
}

@media screen {
  .printContainer {
    padding: 0;
    min-height: 7in;
    height: 7in;
    flex-basis: 7in;
    margin: 0.5in auto;
    width: 10.25in;
    max-width: 10.25in;
    transform: scale(0.35, 0.35);
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3.5in 0 0 -5.125in;
  }
}

@media screen and (min-width: 544px) {
  .printContainer {
    transform: scale(0.55, 0.55);
  }
}

@media screen and (min-width: 768px) {
  .printContainer {
    transform: scale(0.65, 0.65);
  }
}

@media screen and (min-width: 992px) {
  .printContainer {
    transform: scale(0.725, 0.725);
  }
}

@media screen and (min-width: 1200px) {
  .printContainer {
    margin-top: -2.875in;
    transform: scale(0.875, 0.875);
  }
}

@media print {
  .printContainer {
    break-before: page;
  }
}

.printControlsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

@media screen and (min-width: 992px) {
  .printControlsContainer {
    flex-direction: row;
  }
}

@media print {
  .printControlsContainer {
    flex-direction: row;
    display: block;
    width: auto;
  }
}

.calendarPrintTitle {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0;
  padding: 4px;
  line-height: 1em;
  color: var(--voodoo);
}

@media print {
  .calendarPrintTitle {
    color: #000;
  }
}

.calendarDetails {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.calendarDescriptionSection {
  width: 14rem;
  min-width: 10rem;
}

.monthDescriptionSection {
  width: 7rem;
}

.rows {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.row {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: auto;
  flex-basis: auto;
  flex-direction: row;
}

.warningContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 40px;
  left: 100px;
  right: 100px;
  z-index: 3;
  pointer-events: none;
}

.warning {
  border-radius: 12px;
  border: 1px solid var(--sorbus);
  background: #fff;
  color: var(--sorbus);
  margin: auto;
  padding: 4px 8px;
  transition: all 0.5s ease-out;
  pointer-events: all;
}

.warningInactive {
  opacity: 0;
  transform: translate(0, 40px);
  pointer-events: none;
}

.print_preview_full_page {
  padding: 20px;
  height: 100vh;
}

@media screen {
  .print_preview_full_page {
    height: 3in;
    max-height: 3in;
    flex-basis: 3in;
  }
}

@media screen and (min-width: 544px) {
  .print_preview_full_page {
    flex-direction: row;
    height: 4.5in;
    max-height: 4.5in;
    flex-basis: 4.5in;
  }
}

@media screen and (min-width: 768px) {
  .print_preview_full_page {
    flex-direction: row;
    height: 5in;
    max-height: 5in;
    flex-basis: 5in;
  }
}

@media screen and (min-width: 992px) {
  .print_preview_full_page {
    flex-direction: row;
    height: 6in;
    max-height: 6in;
    flex-basis: 6in;
  }
}

@media print {
  .print_preview_full_page {
    padding: 0;
    break-before: page;
    height: 100vh;
    max-height: 100vh;
    flex-basis: 100vh;
  }
}

.annual_print_preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media print {
  .annual_print_preview {
    min-height: 86vh;
  }
}

.pageControl {
  display: none;
  position: fixed;
  bottom: 4rem;
  left: 50%;
  z-index: 10;
  text-align: center;
  width: 200px;
  margin-left: -100px;
}

@media (min-width: 768px) {
  .pageControl {
    display: flex;
  }
}

@media (min-width: 992px) {
  .pageControl {
    margin-left: -170px;
  }
}

@media print {
  .pageControl {
    display: none;
  }
}

.pageControlContents {
  margin: auto;
}

.pageControlButton {
  border: 0;
  background: #fff;
  color: var(--voodoo);
}

.row + .row {
  border-top: 2px solid var(--roseLight);
  margin-top: 2px;
}

@media print {
  .row + .row {
    margin-top: 0;
    border-top: 0;
  }
}
