@import "../../utils/colors.css";

.btn {
  outline: none !important;
}

.btn-primary {
  outline: none !important;
  background: var(--sorbus);
  border-color: var(--sorbus);
  color: #fff;
}

.btn-secondary {
  background: #fff;
  border-color: var(--sorbus);
}

.btn-primary:hover {
  outline: none !important;
  background: var(--sorbusDark);
  border-color: var(--sorbusDark);
}

.btn-primary:focus {
  outline: none !important;
  background: var(--sorbusDark);
  border-color: var(--sorbusDark);
}
