@import '../../utils/colors.css';

.heading {
  display: flex;
}

.formHeading {
  font-size: 1.05rem;
  font-weight: bold;
  line-height: 1em;
  font-weight: 600;
  padding: 1rem 0 0.5rem 0;
}

.formSubHeading {
  font-size: 0.925rem;
  line-height: 1em;
  font-weight: 400;
  padding: 0 0 0.5rem 0;
  margin-top: -0.5rem;
}

.formHeadingButton {
  background: transparent;
  display: flex;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.icon {
  margin-right: 12px;
}
