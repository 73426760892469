.field {
  border: none;
  display: flex;
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}

@media print {
  .field {
    padding: 0.5rem 0;
  }
}

.field::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-weight: normal;
}

@media print {
  .field::placeholder {
    color: transparent;
  }
}
