@import '../../utils/colors.css';

.searchField {
  composes: listItem from './ShortListItem.module.css';
  padding: 0.5rem 0.75rem;
  border: none;
  height: auto;
  margin: 10px;
  border: 1px solid var(--rose);
  border-radius: 10px;
  flex-grow: 1;
  background: var(--white);
}
