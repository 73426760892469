.shortListGroup {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 1rem;
  min-width: 0;
  margin: auto 0 auto 1rem;
}
