.rendererContainerView {
  display: flex;
  flex-grow: 1;
}

.rendererContainerPrintView {
  display: flex;
  flex-grow: 1;
}

@media screen {
  .rendererContainerPrintView {
    min-height: auto;
  }
}
