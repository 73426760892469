@import "../../utils/colors.css";

.toolBarSearch {
  background: #fff;
  border-radius: calc(1.75rem / 2);
  border: 1px solid var(--rose);
  position: relative;
  display: flex;
  height: 1.75rem;
  padding: 0 0.25rem;
}

.searchLabel {
  padding: 0 0.5rem;
  flex-shrink: 0;
  color: var(--rose);
}

.searchInput {
  display: block;
  border: none;
  background: transparent;
  flex-shrink: 1;
  padding: 0.5rem 0.25;
  width: 100%;
}

.searchInput:focus {
  outline: none;
}
