@import "../../utils/colors.css";

.flexRow {
  display: flex;
  margin: 0;
}

.name {
  font-weight: bold;
}

.editLink {
  margin-left: 0.35rem;
}

.removeLink {
  margin-left: auto;
  color: var(--danger);
  font-size: 0.875rem;
}

.inlineIcon {
  font-size: 1.25em;
  margin-left: 0.5rem;
}

.description {
  font-size: 0.75rem;
}
