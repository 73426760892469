:root {
  --rose: #ab9ba9;
  --roseDark: RGBA(168, 156, 168, 0.75);
  --roseMedium: RGBA(168, 156, 168, 0.5);
  --roseNormal: RGBA(168, 156, 168, 0.25);
  --roseLight: RGBA(168, 156, 168, 0.17);
  --roseLightest: RGBA(168, 156, 168, 0.07);
  --roseLightestOpaque: RGBA(248, 247, 248, 1);
  --roseLightestFade: RGBA(248, 247, 248, 0.8);
  --navBarColor: RGBA(36, 134, 195, 1);
  --navBarColorLight: RGBA(42, 140, 201, 1);
  --voodoo: RGBA(16, 114, 175, 1);
  --voodooDark: RGBA(6, 54, 105, 1);
  --lochivar: #4e83bc;
  --lochivarLight: #76b4f719;
  --lochivarDark: #4e83bc;
  --tulip: #e7ab48;
  --burgundy: #9b2537;
  --danger: #d75452;
  --sorbus: #dc6939;
  --sorbusDark: RGBA(200, 84, 37, 1);
}
