@import "../../utils/colors.css";

:root {
  --borderRadius: 0.5rem;
}

.selectGroup {
  display: flex;
  flex-grow: 1;
  margin: 0;
  list-style: none;
  padding: 0;
}

.selectGroup > li {
  padding: 0.25rem;
  border: 1px solid var(--voodoo);
  border-right: none;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
}

.selectGroup > li button {
  outline: none;
}

.selectGroup > li.selectGroupActive,
.selectGroup > li.selectGroupActive button {
  background: var(--voodoo);
  color: #fff;
}

.selectGroup > li:first-child {
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

.selectGroup > li:last-child {
  border-right: 1px solid var(--voodoo);
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.selectButton {
  border: none;
  background: transparent;
  color: var(--voodoo);
  position: relative;
  display: flex;
  flex-grow: 1;
}

.selectButton > * {
  text-align: center;
  display: block;
  margin: auto;
}

.selectGroupContainer {
  display: flex;
  padding-bottom: 0.5rem;
}

.selectGroupLabel {
  flex-grow: 1;
  margin: auto;
}
