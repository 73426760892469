.selectableItem {
  display: flex;
  flex-grow: 1;
  padding: 0.1rem 0;
  min-height: 1.75rem;
  cursor: pointer;
}

.selected {
  background: rgba(0, 0, 0, 0.05);
}

.default {
  background: rgba(0, 0, 0, 0);
}
