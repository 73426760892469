@import "../../utils/colors.css";

.autoCompleteInputGroup {
  position: relative;
}

.flexRow {
  display: flex;
  margin: 0;
}

.autoCompleteResults {
  position: absolute;
  top: 100%;
  left: -6px;
  right: -6px;
  background: #fff;
  border: 2px solid var(--rose);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 2px 2px 4px #fff;
  z-index: 1;
  max-height: 12rem;
  overflow-y: auto;
}

.autoCompleteItem {
  border-bottom: 1px solid var(--rose);
  padding: 0.5rem;
  margin: 0;
  cursor: pointer;
}
.autoCompleteItem:hover {
  background: var(--roseLight);
}

.autoCompleteItem:last {
  border-bottom: none;
}

.autoCompleteWrap {
  display: block;
  border: 2px solid var(--rose);
  border-bottom: none;
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  box-shadow: 2px 2px 4px #fff;
}

.filterList {
  display: flex;
  overflow-x: auto;
}

.filterList input {
  flex-grow: 1;
  width: auto;
  margin-bottom: 0.25rem;
}

.selectedFilterTag {
  display: block;
  border-radius: 0.25rem;
  background: var(--lochivar);
  color: #fff;
  flex-shrink: 0;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  padding: 0.15rem 0.35rem 0.15rem 0.15rem;
  font-size: 0.675rem;
}

.inlineIcon {
  margin-right: 0.5rem;
}

.removeButton {
  background: transparent;
  border: none;
  color: #fff;
  padding-right: 0.35rem;
}
