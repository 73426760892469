@import "../../utils/colors.css";

.barHeading {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.actionable {
  cursor: pointer;
  flex-shrink: 0;
}

.actionable .barHeadingSecondary {
  border: 1px solid var(--sorbus);
  border-radius: 4px;
  padding: 2px 4px;
  color: var(--sorbus);
  margin-top: 4px;
  flex-shrink: 0;
}

.actionable:hover .barHeadingSecondary {
  color: #fff;
  background: var(--sorbus);
}

.barHeadingPrimary {
  composes: truncates from "../../utils/text.css";
  font-size: 1rem;
  font-weight: bold;
  color: var(--voodoo);
  line-height: 1.15em;
  margin: 0;
  width: 100%;
  flex-shrink: 0;
}

.barHeadingSecondary {
  composes: truncates from "../../utils/text.css";
  font-size: 0.75rem;
  color: var(--rose);
  line-height: 1em;
  margin: 0;
}

.barHeadingSecondary i {
  margin-right: 4px;
}
