@import "../../utils/mediaTypes.css";

.rowContainer {
  margin: 0 0 1.25rem 0;
}

@media only screen and (min-width: 48em) {
  .rowContainer {
    margin: 0;
  }
}
