@import "../../utils/mediaTypes.css";

:root {
  --marginSize: 0.5rem;
}

.split {
  display: flex;
  flex-direction: column;
}

@media (min-width: 544px) {
  .split {
    flex-direction: row;
  }

  .split > * {
    flex-grow: 1;
    margin-left: var(--marginSize);
  }

  .split > :first-child {
    margin-right: 0;
    margin-left: 0;
  }
}
