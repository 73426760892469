@import "../../utils/colors.css";
@import "../../utils/grid.module.css";

.textWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-contents: center;
}

.assistantText {
  border-left: 2px solid #ddd;
  color: var(--rose);
  font-size: 0.825rem;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  margin: auto;
  flex-grow: 1;
  font-weight: 200;
  opacity: 0.5;
  display: flex;
  align-items: center;
  align-content: center;
}

@media (only screen and (min-width: 48em)) {
  .assistantText {
    min-height: 3.25rem;
  }
}

.active,
.error {
  opacity: 1;
  font-weight: bold;
}

.error {
  border-color: var(--burgundy);
  color: var(--burgundy);
}
