.errorMessage {
  color: #900;
  text-align: left;
  font-size: 0.875rem;
  border-left: 2px solid #900;
  padding-left: 1rem;
}

.helperMessage {
  composes: errorMessage;
  border-color: hsl(208, 98%, 43%);
  color: #666;
}

.helperMessage a {
  font-weight: bold;
}
