@import "../../utils/colors.css";

.tooltip {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  margin-top: 12px;
  background: var(--sorbus);
  color: #fff;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.25em;
  text-align: center;
  margin-left: -2.5rem;
  width: 5rem;
  padding: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.tooltip::after {
  content: "";
  display: block;
  background: transparent;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  bottom: 100%;
  border: 5px solid transparent;
  border-bottom: 5px solid var(--sorbus);
  z-index: 3;
}

.tooltip.alignBottomRight {
  left: auto;
  right: 0;
}

.tooltip.alignBottomRight::after {
  left: auto;
  right: 5px;
}

.tooltip.alignRight {
  left: 100%;
  margin-left: 0.5rem;
  top: 50%;
  margin-top: -1rem;
}

.tooltip.alignRight::after {
  left: -5px;
  top: 5px;
  bottom: auto;
  border-right: 5px solid var(--sorbus);
  border-bottom: 5px solid transparent;
}

.shortcut {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
  display: block;
}
