@import "./_shared.css";
@import "../../utils/colors.css";

:root {
  --columnSize: 14.2857142857%;
  --targetHeight: 1.65rem;
}

.month {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.month + .month {
  border-left: 2px solid var(--calendarBorderColor);
  margin-left: 2px;
  padding-left: 2px;
}

@media print {
  .month + .month {
    margin: 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.monthViewWeekHeader {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0;
  min-height: 1.5rem;
}

.monthViewWeekHeader.simplified {
  min-height: 0.75rem;
}

@media print {
  .monthViewWeekHeader {
    min-height: 0.75rem;
    background: var(--roseLightest);
  }
}

.weekDayHeading {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: auto;
  display: flex;
  align-items: center;
  align-content: center;
}

.weekDayTitle {
  margin: auto;
  display: block;
  color: var(--rose);
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 500;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.weekDayTitle.simplified {
  font-size: 0.675rem;
}

.weekDayTitle.limited {
  font-size: 0.5rem;
}

@media print {
  .weekDayTitle {
    color: #000;
    font-size: 0.5rem;
    line-height: 0.5rem;
  }
}

.monthViewHeader {
  composes: monthViewWeekHeader;
  background: var(--roseLight);
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 10;
}

.monthViewHeader:hover {
  background: var(--rose);
}
.monthViewHeader:hover .monthName {
  color: #fff;
}

.monthViewHeader.simplified {
  min-height: 0.75rem;
}

@media print {
  .monthViewHeader {
    background: #fff;
    border-bottom: 0;
    height: 1.5em;
    color: #000;
  }
}

.monthName {
  composes: weekDayTitle;
  margin: auto;
  font-size: 0.75rem;
  color: var(--rose);
}

.monthName.simplified {
  font-size: 0.675rem;
}

.monthName.limited {
  font-size: 0.5rem;
}

.monthName.limitedForPrint {
  font-size: 0.35rem;
}

@media print {
  .monthName {
    color: #000;
    height: 1em;
  }
}

.week {
  display: flex;
  flex-direction: row;
  flex-basis: 1rem;
  flex-align: stretch;
  flex-grow: 1;
  position: relative;
  min-height: 1rem;
}

.week:last-child {
  border-bottom: 0;
}

.dayPartition {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.eventsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: 0;
  top: 1.5rem;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
}

.eventsContainer.simplified {
  top: 1rem;
}

@media print {
  .eventsContainer {
    top: 1rem;
  }
}

.eventRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  pointer-events: none;
}

.dragUIContainer {
  composes: eventsContainer;
  pointer-events: none;
  flex-direction: row;
  z-index: 11;
}

.dragUIContainer,
.dragUIContainer.simplified {
  top: 0;
}

.dragUIColumn {
  display: flex;
  flex-basis: var(--columnSize);
  width: var(--columnSize);
  max-width: var(--columnSize);
  min-width: var(--columnSize);
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
}

.dragUITarget {
  display: flex;
  flex-basis: var(--targetHeight);
  height: var(--targetHeight);
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid rgba(0, 255, 0, 0.4);
  border-radius: 0.5rem;
  line-height: 1rem;
  padding: 0.5rem;
  text-align: center;
  color: rgba(0, 255, 0, 0.1);
  font-size: 0.875rem;
  font-weight: bold;
}

.moreView {
  position: absolute;
  bottom: 0;
  left: 2px;
  right: 2px;
  z-index: 1;
  border-top: 1px solid var(--roseLight);
  background: rgba(255, 255, 255, 0.6);
  color: var(--rose);
  cursor: pointer;
  display: block;
  pointer-events: all;
  text-align: center;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.moreView:hover {
  background: var(--rose);
  color: #fff;
}

.moreView.simplified {
  padding: 0.075rem;
  font-size: 0.625rem;
}

.moreView.limited {
  padding: 0.075rem;
  font-size: 0.5625rem;
}
