.placeHolderContainer {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100vh;
  width: 100%;
  padding: 1rem;
}

.content {
  margin: auto;
  padding: 1rem;
  border: 4px solid #ab9ba9;
  border-radius: 20px;
  color: #ab9ba9;
  max-width: 300px;
  text-align: center;
}

.modal {
  composes: content;
  max-width: 480px;
}
