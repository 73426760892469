@import "../../utils/colors.css";

.deleteForm {
  border-top: 1px solid var(--roseLight);
  margin-top: 2rem;
  padding-top: 1rem;
}

.deleteLink {
  color: var(--danger);
  font-weight: bold;
}
