@import "../../utils/mediaTypes.css";

.modalLayer {
  pointer-events: none;
  z-index: 100;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* :global references override Bootstrap 4 */
.modalContainer {
  width: 100%;
  pointer-events: all;
}

@media (min-width: 768px) {
  .modalContainer {
    min-width: 375px;
    max-width: 425px;
  }
}

@media (min-width: 544px) {
  .modalContainer {
    max-width: 425px;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .modalContainer.extended {
    max-width: 544px;
  }
}

@media (min-width: 544px) {
  .modalContainer.extended {
    max-width: 544px;
    margin: auto;
  }
}

.modalContainer.modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border: 1px solid #eee;
}

.fadeIn {
  animation: animateIn 0.5s ease-out 0s 1;
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
