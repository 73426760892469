@import "../../utils/colors.css";
@import "./_shared.css";

.toolBarButtonGroup {
  display: flex;
}
.toolBarButtonGroup,
.toolBarButtonGroup li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.toolBarButtonGroup button {
  color: var(--voodoo);
  background: transparent;
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem;
}
.toolBarButtonGroup button:active {
  color: var(--roseDark);
}

.toolBarButtonGroup li {
  border: 1px solid var(--voodoo);
  border-left: none;
  font-size: 0.875rem;
}
.toolBarButtonGroup li:first-child {
  border-left: 1px solid var(--voodoo);
  border-top-left-radius: var(--toolBarButtonGroupRadius);
  border-bottom-left-radius: var(--toolBarButtonGroupRadius);
}

.toolBarButtonGroup li:last-child {
  border-top-right-radius: var(--toolBarButtonGroupRadius);
  border-bottom-right-radius: var(--toolBarButtonGroupRadius);
}
