@import "../../utils/colors.css";

:root {
  --itemBackgroundColor: #fff;
  --settingsIconColor: #ab9ba9;
  --itemHoverBackgroundColor: RGBA(79, 150, 137, 0.02);
  --itemBorderColor: #ccc;
}

.listItem {
  background: var(--itemBackgroundColor);
  display: flex;
  min-width: 0;
  margin: 0;
  height: 64px;
  border-bottom: 1px solid var(--itemBorderColor);
}

.listItem:hover {
  background: var(--itemHoverBackgroundColor);
}

.listItem.selected {
  background: var(--roseLight);
}

.loadingContainer {
  margin: auto 1rem;
}

.button {
  composes: listItem;
  padding: 0;
  flex-shrink: 1;
  flex-grow: 1;
  border: none;
  text-align: left;
  padding: 0 0 0 1rem;
}

.button,
.button:hover {
  background: transparent;
}

.button:focus {
  outline: 0;
}

.button > div {
  flex-shrink: 0;
}
