:root {
  --buttonSizeSmall: 40px;
}

.view {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  align-content: center;
  background: #4f9689;
  border: none;
  color: #fff;
  border-radius: calc(var(--buttonSizeSmall) / 2);
  height: var(--buttonSizeSmall);
  width: var(--buttonSizeSmall);
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin: auto;
}

.view:focus {
  outline: 0;
}

.name {
  display: block;
  margin: auto;
}
